<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-pica">
        <img src="@/assets/casestudy/pica8-inc-logo.png" alt="Pica8">
        <span class="sep"></span>
        <p>Pica8 is breaking barriers to truly customizable application performance through open networking. With its Linux-based PicOS™ network operating system, Pica8 empowers a wide variety of
          white box and brite box switches to enable easy integration with existing Layer-2 / Layer-3 networks and unlimited SDN scalability through OpenFlow. Since 2009, Pica8 has pioneered new open
          networking technologies such as Linux-based networking, CrossFlow networking, vASIC® and Table Type Patterns. Through ongoing innovation, Pica8 is a trusted brand that unlocks the potential
          of made-to-order networking, offering a mainstream alternative to legacy proprietary systems. Pica8 is a global company with over 500 customers, and is headquartered in Palo Alto,
          California.</p>
      </div>
    </div>

    <div class="laptop-img pica-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/pica8-main-img.png" alt="Pica8">
      </div>
    </div>

    <div class="container-about pica-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/pica-diamond.svg" alt="Pica8">
        <h3>About the project</h3>
        <p>What happens when a innovative OS company breaks the barriers of legacy proprietary systems switches? It’s easy, they bring a cool operating system for switches that everyone loves!
          Software Defined Networking for the win!<br/>
          Our collaboration with Pica8 has started early 2015 and has gone from small module development to a much wider scope that comes to complete their inventory systems and assure that their
          process is simplified and their end-customers can easily buy or extend their licenses.<br/>
          We also ensure part of their world-wide support, thus playing a role in the customer care as well..</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-services__content">
          <h3>What we do</h3>

          <div class="wwd-left">
            <h4>Managed services</h4>
            <img src="@/assets/casestudy/web-app2.svg" width="60px" height="60px">
            <h4>Support</h4>
            <img src="@/assets/casestudy/maintenance-icon2.svg" width="60px" height="60px">
            <h4>SEO</h4>
            <img src="@/assets/casestudy/consultancy-icon2.svg" width="60px" height="60px">
          </div>

          <span class="sep vertical"></span>

          <div class="wwd-right">
            <img src="@/assets/casestudy/managed-services2.svg" width="60px" height="60px">
            <h4>Custom Web App's</h4>
            <img src="@/assets/casestudy/support-icon2.svg" width="60px" height="60px">
            <h4>Maintenance</h4>
            <img src="@/assets/casestudy/seo-icon2.svg" width="60px" height="60px">
            <h4>Consultancy</h4>
          </div>
        </div>
      </div>

    </div>

    <testimonial tsTitle="#F05A28">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Niraj Jain, VP of Business Development</p>
        <p class="testimonial-text__content">"Scriptics has been a great development partner with thoughtful analysis, design, and implementation of our web applications. Their creativity and can do
          attitude makes it a delight to work with them." </p>
      </div>
    </testimonial>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name:'Fyber'}" class="left">
              <img src="@/assets/casestudy/btn-fyber.jpg" alt="rnts">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Startablish'}" class="right">
              <img src="@/assets/casestudy/btn-startablish.png" alt="startablish">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#F05A28"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Pica8",
  components: {LetsChat, Testimonial, ContactUs, Footer}
}
</script>

<style lang="scss">

.logo-pica img {
  max-width: 320px;
}

.pica-container, .pica-about {
  background: #F05A28;
}

.pica-laptop {
  background: #2d2d2d;
}

.testimonial-title-text, .testimonial-text__content, .container-about h3, .container-about p {
  color: #fafafa;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #F05A28 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>